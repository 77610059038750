@use "@bfl/components/theming/variables" as variables;
@use "@bfl/components/theming/constants" as constants;
@use "@bfl/components/theming/utils" as utils;

.bold {
  @include utils.bfc-font-type(variables.$bold-font);
}

// override the checkbox color when checked
.mat-mdc-checkbox-checked .mdc-checkbox .mdc-checkbox__background {
  background-color: variables.$bkw-black !important;
  border-color: variables.$bkw-black !important;
}
